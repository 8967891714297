<template>
  <div class="row">
    <div class="col-12">
      <div
        class="page-title-box d-flex align-items-center justify-content-between"
      >
        <h4 class="mb-0">
          <breadcrumb :route="$route.matched[$route.matched.length - 1]" inline-template>
            <span>
              <span class="active" v-if="!route.meta.bcDynamic">
                {{staticValue}}
              </span>
              <span class="active" v-if="route.meta.bcDynamic">
                <template v-if="value">
                  {{formattedValue}}
                </template>
                <template v-if="!value">
                  {{loadingText}}
                </template>
              </span>
            </span>
          </breadcrumb>
        </h4>

        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li v-for="(item, index) in $route.matched" :key="index" class="breadcrumb-item">
              <breadcrumb :route="item" inline-template>
                <span v-if="index === $route.matched.length - 1">
                  <span class="active" v-if="!route.meta.bcDynamic">
                    {{staticValue}}
                  </span>
                  <span class="active" v-if="route.meta.bcDynamic">
                    <template v-if="value">
                      {{formattedValue}}
                    </template>
                    <template v-if="!value">
                      {{loadingText}}
                    </template>
                  </span>
                </span>
                <span v-else>
                  <router-link v-if="!route.meta.bcDynamic" :to="{name: route.name}">
                    {{staticValue}}
                  </router-link>
                  <router-link v-if="route.meta.bcDynamic" :to= "{name: route.name}">
                    <template v-if="value">
                      {{formattedValue}}
                    </template>
                    <template v-if="!value">
                      {{loadingText}}
                    </template>
                  </router-link>
                </span>
              </breadcrumb>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
Vue.component('breadcrumb', {
  props: ['route'],
  beforeCreate () {
    this.$options.computed.value = function () {
      if (this.route.meta.bcGetter) {
        return this.$store.getters['breadcrumbs/' + this.route.meta.bcGetter]
      } else {
        return null
      }
    }
  },
  computed: {
    formattedValue () { return this.value },
    loadingText () {
      const loadingText = this.route.meta.bcLoadingText
      return loadingText ? loadingText : this.$t('links.loading') + '...'
    },
    staticValue () {
      let breadcrumb = this.route.meta.breadcrumb
      if (typeof breadcrumb === 'function') {
        breadcrumb = breadcrumb.call(this, this.$route.params)
      }
      return breadcrumb
    }
  }
})
export default {
}
</script>
