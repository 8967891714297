<template>
  <span>
    <li v-for="(item, index) of items" :key="index">
      <router-link
          v-show="(item.isShow || typeof item.isShow === 'undefined') && isItemAllowed(item.tag)"
          :to="'/client/gameserver/' + ugid + item.link"
          class="side-nav-link-ref">
        <span class="badge rounded-pill bg-info float-end" v-if="item.tag === 'updates' && updatesCount !== 0">{{ updatesCount }}</span>
        <span class="badge rounded-pill bg-danger float-end" v-else-if="item.tag === 'scheduler' && scheduler_errors">!</span>
        <span v-if="item.tag === 'ftp'" class="badge rounded-pill bg-warning float-end">Beta</span>
        <span>{{ $t(item.label) }}</span>
      </router-link>
    </li>
  </span>
</template>

<script>
import axios from '@/modules/Axios'

export default {
  computed: {
    ugid() {
      return this.$route.params.ugid
    }
  },
  data() {
    return {
      items: [
        {link: '/control', icon: 'fa fa-play', label: 'links.control'},
        {link: '/settings', icon: 'fa fa-cog', label: 'links.settings', tag: 'settings', isShow: false},
        {link: '/statistic', icon: 'fa fa-chart-pie', label: 'links.statistics', tag: 'statistic'},
        // { link: '/configuration', icon: 'fa fa-cogs', label: 'Конфигурация' },
        {link: '/cores', icon: 'fa fa-wrench', label: 'links.cores', tag: 'cores', isShow: false},
        {link: '/addons', icon: 'fa fa-plug', label: 'links.addons', tag: 'addons', isShow: false},
        {link: '/umod', icon: 'fa fa-plug', label: 'links.umod_addons', tag: 'umod', isShow: false},
        {link: '/updates', icon: 'fa fa-wrench', label: 'links.updates', tag: 'updates', isShow: false},
        {link: '/scheduler', icon: 'fa fa-calendar', label: 'links.scheduler', tag: 'scheduler', isShow: false},
        {link: '/utilities', icon: 'fa fa-microchip', label: 'links.utilities', tag: 'utilities', isShow: false},
        {link: '/db', icon: 'fa fa-database', label: 'links.database', tag: 'database', isShow: false},
        // {link: '/ftp', icon: 'fa fa-folder-tree', label: 'links.ftp', tag: 'ftp', isShow: false},
      ],
      updatesCount: 0,
      scheduler_errors: false
    }
  },
  methods: {
    isItemAllowed(tag) {
      if (tag === 'cores' && this.$store.state.server.gid !== 1) return false
      if (tag === 'database' && !this.$store.state.server.database_allowed) return false
      if (tag === 'umod' && !this.$store.state.server.umod) return false
      if (tag === 'settings' && !this.$store.state.server.menu_settings) return false
      if (tag === 'statistic' && !this.$store.state.server.menu_statistic) return false
      if (tag === 'addons' && !this.$store.state.server.menu_addons) return false
      if (tag === 'updates' && !this.$store.state.server.menu_updates) return false
      if (tag === 'utilities' && !this.$store.state.server.menu_utilities) return false
      return true
    },
    /**
     * remove active and mm-active class
     */
    _removeAllClass(className) {
      const els = document.getElementsByClassName(className);
      while (els[0]) {
        els[0].classList.remove(className);
      }
    },
    _activateMenuDropdown() {
      this._removeAllClass("mm-active");
      this._removeAllClass("mm-show");

      var links = document.getElementsByClassName("side-nav-link-ref");
      var matchingMenuItem = null;
      const paths = [];

      for (var i = 0; i < links.length; i++) {
        paths.push(links[i]["pathname"]);
      }
      var itemIndex = paths.indexOf(window.location.pathname);
      if (itemIndex === -1) {
        const strIndex = window.location.pathname.lastIndexOf("/");
        const item = window.location.pathname.substr(0, strIndex).toString();
        matchingMenuItem = links[paths.indexOf(item)];
      } else {
        matchingMenuItem = links[itemIndex];
      }

      if (matchingMenuItem) {
        matchingMenuItem.classList.add("active");
        var parent = matchingMenuItem.parentElement;

        /**
         * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
         * We should come up with non hard coded approach
         */
        if (parent) {
          parent.classList.add("mm-active");
          const parent2 = parent.parentElement.closest("ul");
          if (parent2 && parent2.id !== "side-menu") {
            parent2.classList.add("mm-show");

            const parent3 = parent2.parentElement;
            if (parent3) {
              parent3.classList.add("mm-active");
              var childAnchor = parent3.querySelector(".has-arrow");
              var childDropdown = parent3.querySelector(".has-dropdown");
              if (childAnchor) childAnchor.classList.add("mm-active");
              if (childDropdown) childDropdown.classList.add("mm-active");

              const parent4 = parent3.parentElement;
              if (parent4 && parent4.id !== "side-menu") {
                parent4.classList.add("mm-show");
                const parent5 = parent4.parentElement;
                if (parent5 && parent5.id !== "side-menu") {
                  parent5.classList.add("mm-active");
                  const childanchor = parent5.querySelector(".is-parent");
                  if (childanchor && parent5.id !== "side-menu") {
                    childanchor.classList.add("mm-active");
                  }
                }
              }
            }
          }
        }
      }
    },
    async getUpdatesCount () {
      try {
        const response = await axios.post('/Game/Updates.php', {
          ugid: this.$route.params.ugid,
          mode: 'list'
        })
        if (response.data.server < response.data.steam) {
          this.updatesCount++
        }
      } catch (error) {
        console.log(error.message)
      }
    },
    async getSchedulerErrors () {
      try {
        const response = await axios.post('/Game/Scheduler.php', {
          ugid: this.$route.params.ugid
        })
        if (response.data.status == 'success') {
          this.scheduler_errors = response.data.cronlist.some(function(item) {
            return (item.active == 1 && item.log_errors);
          })
        }
      } catch (error) {
        console.log(error.message)
      }
    },
  },
  mounted() {
    //this._activateMenuDropdown();
    if (this.$store.state.session.subuser) {
      if (this.$store.state.session.permissions && this.$store.state.session.permissions[this.$route.params.ugid]['settings']) {
        this.items.find(item => item.tag === 'settings').isShow = true
      }
      if (this.$store.state.session.permissions && this.$store.state.session.permissions[this.$route.params.ugid]['addons']) {
        this.items.find(item => item.tag === 'cores').isShow = true
        this.items.find(item => item.tag === 'addons').isShow = true
        this.items.find(item => item.tag === 'umod').isShow = true
      }
      if (this.$store.state.session.permissions && this.$store.state.session.permissions[this.$route.params.ugid]['updates']) {
        this.items.find(item => item.tag === 'updates').isShow = true
      }
      if (this.$store.state.session.permissions && this.$store.state.session.permissions[this.$route.params.ugid]['control']) {
        this.items.find(item => item.tag === 'scheduler').isShow = true
      }
      if (this.$store.state.session.permissions && this.$store.state.session.permissions[this.$route.params.ugid]['utilities']) {
        this.items.find(item => item.tag === 'utilities').isShow = true
      }
      // if (this.$store.state.session.permissions && this.$store.state.session.permissions[this.$route.params.ugid]['ftp']) {
      //   this.items.find(item => item.tag === 'ftp').isShow = true
      // }
      // if (this.$store.state.session.permissions && this.$store.state.session.permissions[this.$route.params.ugid]['storage']) {
      //   this.items.find(item => item.tag === 'storage').isShow = true
      // }
    } else {
      this.items.find(item => item.tag === 'settings').isShow = true
      this.items.find(item => item.tag === 'cores').isShow = true
      this.items.find(item => item.tag === 'addons').isShow = true
      this.items.find(item => item.tag === 'umod').isShow = true
      this.items.find(item => item.tag === 'updates').isShow = true
      this.items.find(item => item.tag === 'scheduler').isShow = true
      this.items.find(item => item.tag === 'utilities').isShow = true
      this.items.find(item => item.tag === 'database').isShow = true
      // this.items.find(item => item.tag === 'ftp').isShow = true
      // this.items.find(item => item.tag === 'storage').isShow = true
    }
    /*    axios.post('/Game/Updates.php', {
          ugid: this.$route.params.ugid,
          mode: 'oxide'
        })
          .then(response => {
            if (response.data.current !== response.data.last) {
              this.updatesCount++
            }
          })
          .catch(error => {
            console.log(error.message)
          })*/
    this.getUpdatesCount()
    this.getSchedulerErrors()
  }
}
</script>
