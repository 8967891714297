<script>
export default {

}
</script>

<template>
    <footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">{{ new Date().getFullYear()}} © Adman.com</div>
        </div>
    </div>
</footer>
</template>
