<script>
import {
    layoutComputed
} from "@/state/helpers";
import Vertical from "./vertical";
import Horizontal from "./horizontal";

/**
 * Main Layout
 */
export default {
    components: {
        Vertical,
        Horizontal,
    },
    data() {
        return {};
    },
    computed: {
        ...layoutComputed,
    },
    mounted() {
        document.body.classList.remove('authentication-bg')
    },
    methods: {},
};
</script>

<template>
<div>
    <Vertical v-if="layoutType === 'vertical'" :layout="layoutType">
        <slot />
    </Vertical>

    <Horizontal v-if="layoutType === 'horizontal'" :layout="layoutType">
        <slot />
    </Horizontal>
</div>
</template>

<style>
    button {
        outline: 0 !important;
    }

    button:focus {
        outline: 0 !important;
    }

    .consoleWrapper {
        border: 1px solid #c2cfd6;
    }

    .consoleWrapper .console-output .Error,
    .consoleWrapper .chat-output .Error {
        color: #a94442;
        background-color: #f2dede;
        border-color: #ebccd1;
    }

    .consoleWrapper .console-output .Warning,
    .consoleWrapper .chat-output .Warning {
        color: #8a6d3b;
        background-color: #fcf8e3;
        border-color: #faebcc;
    }

    .consoleWrapper .console-output .command {
        color: #8a6d3b;
        background-color: #fcf8e3;
        border-color: #faebcc;
    }

    .consoleWrapper .console-output,
    .consoleWrapper .chat-output {
        overflow: auto;
        font-family: inherit;
    }

    .consoleWrapper .time {
        width: 65px;
        color: #6a6c6f;
        background-color: #f7f9fa !important;
        border-right: 1px solid #ddd;
        vertical-align: top;
        padding-left: 5px;
        margin-right: 5px;
        display: table-cell;
    }

    .consoleWrapper .console-output .message,
    .consoleWrapper .chat-output .message {
        padding-left: 5px;
    }

    .consoleWrapper .console-output .row,
    .consoleWrapper .chat-output .row {
        margin: 0px;
        display: table;
        width: 100%;
    }
</style>
