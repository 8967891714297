<template>
  <span>
    <router-link v-for="(item, index) of items" :key="index"
       v-show="(item.isShow || typeof item.isShow === 'undefined') && isItemAllowed(item.tag)"
       class="col dropdown-item side-nav-link-ref"
       :to="'/client/gameserver/' + ugid + item.link">
      <span class="badge rounded-pill bg-info float-end" v-if="item.tag === 'updates' && updatesCount !== 0">{{ updatesCount }}</span>
      <span class="badge rounded-pill bg-danger float-end" v-else-if="item.tag === 'scheduler' && scheduler_errors">!</span>
      <span v-if="item.tag === 'ftp'" class="badge rounded-pill bg-warning float-end">Beta</span>
      <span>{{ $t(item.label) }}</span>
    </router-link>
  </span>
</template>

<script>
import axios from '@/modules/Axios'

export default {
  computed: {
    ugid() {
      return this.$route.params.ugid
    }
  },
  data() {
    return {
      items: [
        {link: '/control', icon: 'fa fa-play', label: 'links.control'},
        {link: '/settings', icon: 'fa fa-cog', label: 'links.settings', tag: 'settings', isShow: false},
        {link: '/statistic', icon: 'fa fa-chart-pie', label: 'links.statistics', tag: 'statistic'},
        // { link: '/configuration', icon: 'fa fa-cogs', label: 'Конфигурация' },
        {link: '/cores', icon: 'fa fa-wrench', label: 'links.cores', tag: 'cores', isShow: false},
        {link: '/addons', icon: 'fa fa-plug', label: 'links.addons', tag: 'addons', isShow: false},
        {link: '/umod', icon: 'fa fa-plug', label: 'links.umod_addons', tag: 'umod', isShow: false},
        {link: '/updates', icon: 'fa fa-wrench', label: 'links.updates', tag: 'updates', isShow: false},
        {link: '/scheduler', icon: 'fa fa-calendar', label: 'links.scheduler', tag: 'scheduler', isShow: false},
        {link: '/utilities', icon: 'fa fa-microchip', label: 'links.utilities', tag: 'utilities', isShow: false},
        {link: '/db', icon: 'fa fa-database', label: 'links.database', tag: 'database', isShow: false},
        // {link: '/ftp', icon: 'fa fa-file-tree', label: 'links.ftp', tag: 'ftp', isShow: false},
      ],
      updatesCount: 0,
      scheduler_errors: false
    }
  },
  methods: {
    isItemAllowed(tag) {
      if (tag === 'cores' && this.$store.state.server.gid !== 1) return false
      if (tag === 'database' && !this.$store.state.server.database_allowed) return false
      if (tag === 'umod' && !this.$store.state.server.umod) return false
      if (tag === 'settings' && !this.$store.state.server.menu_settings) return false
      if (tag === 'statistic' && !this.$store.state.server.menu_statistic) return false
      if (tag === 'addons' && !this.$store.state.server.menu_addons) return false
      if (tag === 'updates' && !this.$store.state.server.menu_updates) return false
      if (tag === 'utilities' && !this.$store.state.server.menu_utilities) return false
      return true
    },
    /**
     * remove active and mm-active class
     */
    _removeAllClass(className) {
      const els = document.getElementsByClassName(className);
      while (els[0]) {
        els[0].classList.remove(className);
      }
    },

    activateParentDropdown() {
      const resetParent = (el) => {
        const parent = el.parentElement;
        this._removeAllClass("mm-active");
        this._removeAllClass("mm-show");
        if (parent) {
          parent.classList.remove("active");
          const parent2 = parent.parentElement;
          if (parent2) {
            parent2.classList.remove("active");
            const parent3 = parent2.parentElement;
            if (parent3) {
              parent3.classList.remove("active");
              const parent4 = parent3.parentElement;
              if (parent4) {
                parent4.classList.remove("active");
                const parent5 = parent4.parentElement;
                if (parent5) {
                  parent5.classList.remove("active");
                  const menuelement = document.getElementById(
                      "topnav-menu-content"
                  );
                  if (menuelement !== null)
                    if (menuelement.classList.contains("show"))
                      document
                          .getElementById("topnav-menu-content")
                          .classList.remove("show");
                }
              }
            }
          }
        }
      };
      var links = document.getElementsByClassName("side-nav-link-ref");
      var matchingMenuItem = null;
      for (let i = 0; i < links.length; i++) {
        // reset menu
        resetParent(links[i]);
      }
      for (var i = 0; i < links.length; i++) {
        if (window.location.pathname === links[i].pathname) {
          matchingMenuItem = links[i];
          break;
        }
      }
      if (matchingMenuItem) {
        matchingMenuItem.classList.add("active");
        var parent = matchingMenuItem.parentElement;
        if (parent) {
          parent.classList.add("active");
          const parent2 = parent.parentElement;
          if (parent2) {
            parent2.classList.add("active");
          }
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("active");
            var childAnchor = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("active");
          }

          const parent4 = parent3.parentElement;
          if (parent4) parent4.classList.add("active");
          const parent5 = parent4.parentElement;
          if (parent5) parent5.classList.add("active");
        }
      }
    },
    async getUpdatesCount () {
      try {
        const response = await axios.post('/Game/Updates.php', {
          ugid: this.$route.params.ugid,
          mode: 'list'
        })
        if (response.data.server < response.data.steam) {
          this.updatesCount++
        }
      } catch (error) {
        console.log(error.message)
      }
    },
    async getSchedulerErrors () {
      try {
        const response = await axios.post('/Game/Scheduler.php', {
          ugid: this.$route.params.ugid
        })
        if (response.data.status === 'success') {
          this.scheduler_errors = response.data.cronlist.some(function(item) {
            return (item.active === 1 && item.log_errors);
          })
        }
      } catch (error) {
        console.log(error.message)
      }
    },
  },
  mounted() {
    this.activateParentDropdown();
    if (this.$store.state.session.subuser) {
      if (this.$store.state.session.permissions && this.$store.state.session.permissions[this.$route.params.ugid]['settings']) {
        this.items.find(item => item.tag === 'settings').isShow = true
      }
      if (this.$store.state.session.permissions && this.$store.state.session.permissions[this.$route.params.ugid]['addons']) {
        this.items.find(item => item.tag === 'cores').isShow = true
        this.items.find(item => item.tag === 'addons').isShow = true
        this.items.find(item => item.tag === 'umod').isShow = true
      }
      if (this.$store.state.session.permissions && this.$store.state.session.permissions[this.$route.params.ugid]['control']) {
        this.items.find(item => item.tag === 'scheduler').isShow = true
      }
      if (this.$store.state.session.permissions && this.$store.state.session.permissions[this.$route.params.ugid]['updates']) {
        this.items.find(item => item.tag === 'updates').isShow = true
      }
      if (this.$store.state.session.permissions && this.$store.state.session.permissions[this.$route.params.ugid]['utilities']) {
        this.items.find(item => item.tag === 'utilities').isShow = true
      }
      // if (this.$store.state.session.permissions && this.$store.state.session.permissions[this.$route.params.ugid]['ftp']) {
      //   this.items.find(item => item.tag === 'ftp').isShow = true
      // }
      // if (this.$store.state.session.permissions && this.$store.state.session.permissions[this.$route.params.ugid]['storage']) {
      //   this.items.find(item => item.tag === 'storage').isShow = true
      // }
    } else {
      this.items.find(item => item.tag === 'settings').isShow = true
      this.items.find(item => item.tag === 'cores').isShow = true
      this.items.find(item => item.tag === 'addons').isShow = true
      this.items.find(item => item.tag === 'umod').isShow = true
      this.items.find(item => item.tag === 'updates').isShow = true
      this.items.find(item => item.tag === 'scheduler').isShow = true
      this.items.find(item => item.tag === 'utilities').isShow = true
      this.items.find(item => item.tag === 'database').isShow = true
      // this.items.find(item => item.tag === 'ftp').isShow = true
      // this.items.find(item => item.tag === 'storage').isShow = true
    }
    /*    axios.post('/Game/Updates.php', {
          ugid: this.$route.params.ugid,
          mode: 'oxide'
        })
          .then(response => {
            if (response.data.current !== response.data.last) {
              this.updatesCount++
            }
          })
          .catch(error => {
            console.log(error.message)
          })*/
    this.getUpdatesCount()
    this.getSchedulerErrors()
  }
}
</script>
